<template>
	<v-card
		elevation="0"
		class="ma-0 pa-0 overflow-y-auto"
		:height="userFormHeight"
	>
		<v-card-title>
			FREQUENTLY ASK QUESTIONS (FAQ)
		</v-card-title>
		
		<v-card-text class="px-0">
			<v-expansion-panels accordion flat focusable>
				<v-expansion-panel
					v-for="item in FAQArray"
					:key="item.id"
				>
					<v-expansion-panel-header>{{ item.question }}</v-expansion-panel-header>
					<v-expansion-panel-content class="pl-5">
						<font v-html="item.answer"></font>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card-text>
	</v-card>
</template>

<script>

import { mapGetters } from 'vuex';

var timeoutData = null;

export default {
	data () {
		return {
			FAQArray: []
		}
    },
	methods: {
		getFAQ () {
			
			clearTimeout(timeoutData);
			
			this.$http.get('helpcenter/user')
			.then((resp) => {
				this.FAQArray = resp.data;
			})
			.catch(() => {
				//retry
				timeoutData = setTimeout(() => {
					this.getFAQ();
				}, 10000);
			})
		},
	},
	mounted(){
		this.getFAQ();
	},computed: {
		...mapGetters(['userFormHeight'])
	},
}

</script>